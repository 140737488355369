import React, {useEffect, useState} from 'react'
import firebase from '../api/firebase.api'
import FirebaseStorageVideo from "../components/firebase.storage.video";
import {MDBSpinner} from "mdbreact";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const Home = () => {
	const [config, setConfig] = useState(null)
	const history = useHistory();

	useEffect(()=>{
		firebase
			.firestore()
			.collection("Config")
			.doc("home-page")
			.get()
			.then((doc)=>{
				const configData = doc.data();
				setConfig(configData)
			})
	}, [])

	if(config === null)
		return <MDBSpinner/>

	return(
		<section id="home" className="text-center">
			<h2 style={{fontWeight: "500", fontSize: "1.3rem", marginTop: "4rem"}}>
				{config.text}
			</h2>
			<FirebaseStorageVideo
				src={config.videoStorageRef}
				style={{width: "40vw"}}
			/>
			<Button
				color="primary"
				variant="contained"
				className="mb-4"
				size="large"
				onClick={()=>{history.push("/inscription")}}
			>
				S'inscrire au programme
			</Button>
		</section>
	)
}

export default Home