import React, {useContext} from "react";
import {Redirect, Route, Switch} from 'react-router-dom';

import UserContext from "../context/user.context";

import CustomersListPage from "../pages/customersList.page";
import QuestionnairePage from "../pages/questionnaire.page";
import ResultPage from "../pages/result.page";
import DocumentsListPage from "../pages/documentsList.page";
import QuestionnaireEndPage from "../pages/questionnaire.end.page";

const ConnectedRouter = () => {
    const {user} = useContext(UserContext)
    const {config} = user;

    if(config.isAdmin && location.pathname === "/")
        return <Redirect to="/clients"/>

    if(!config.isAdmin && location.pathname === "/")
        return <Redirect to="/questionnaire"/>

    return (
        <Switch>
            <Route path='/clients' exact component={CustomersListPage} />

            <Route path='/questionnaire' exact component={QuestionnairePage} />
            <Route path='/questionnaire/fin' exact component={QuestionnaireEndPage} />


            <Route path='/resultats/:userId?' exact component={ResultPage} />
            <Route path='/documents/:userId?' component={DocumentsListPage} />

            <Route>
                404
            </Route>
        </Switch>
    );
};

export default ConnectedRouter;
