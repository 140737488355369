import firebase from "../api/firebase.api";

const getDocuments = (spaceUser) => (
	firebase.storage()
		.ref(spaceUser)
		.listAll()
)

export {
	getDocuments
}