import React from "react";
import NumRow from "./row.num";

const DetailsNum = ({numData}) => (
	<div
		id="result-num-detail"
		style={{marginTop: "1rem", marginBottom: "1rem", fontSize: "1.3rem", fontWeight: "500"}}
	>
		<NumRow
			text={"Chemin de vie :"}
			detail={numData.lifePath.details}
			result={numData.lifePath.calc}
		/>
		<NumRow
			text={"Année universelle :"}
			detail={numData.univYear.details}
			result={numData.univYear.calc}
		/>
		<NumRow
			text={`Année personnelle pour de vie ${new Date().getFullYear()} :`}
			detail={numData.personalYear.details}
			result={numData.personalYear.calc}
		/>
		<NumRow
			text={`Chiffre actif (prénom) :`}
			detail={numData.activeDigit.details}
			result={numData.activeDigit.calc}
		/>
		<NumRow
			text={`Chiffre héréditaire (nom) :`}
			detail={numData.hereditaryDigit.details}
			result={numData.hereditaryDigit.calc}
		/>
		<NumRow
			text={`Chiffre d'expression (nom + prénom) :`}
			detail={numData.expressionDigit.details}
			result={numData.expressionDigit.calc}
		/>
		<NumRow
			text={`Chiffre intime (voyelles) :`}
			detail={numData.personalDigit.details}
			result={numData.personalDigit.calc}
		/>
		<NumRow
			text={`Chiffre de réalisation :`}
			detail={numData.realisationDigit.details}
			result={numData.realisationDigit.calc}
		/>
	</div>
)

export default DetailsNum