import React, {useContext} from "react";

import {MDBRow} from "mdbreact";
import UserContext from "../../context/user.context";

import NavLink from "./navlink.layout";

const NavbarLayout = () => {
	const {user} = useContext(UserContext)

	return(
		<div style={{backgroundColor: "white", textAlign: "center"}}>
			<MDBRow center={true} className="mx-0">
				{user.config.isAdmin ? <AdminNavBarLink/> : <CustomerNavBarLink/>}
			</MDBRow>
		</div>
	)
}

const AdminNavBarLink = () => (
	<React.Fragment>
		<NavLink icon="list" text="Mes clients" url="/clients"/>
	</React.Fragment>
)

const CustomerNavBarLink = () => (
	<React.Fragment>
		<NavLink icon="poll" text="Résultats" url="/resultats"/>
		<NavLink icon="folder-open" text="Documents" url="/documents"/>
	</React.Fragment>
)

export default NavbarLayout