import React, {useContext, useEffect, useState} from 'react'
import {MDBSpinner} from "mdbreact";

import RadarPsy from './psy/radar.psy'
import {getResponse} from "../../model/questions.model";
import firebase from "../../api/firebase.api";
import UserContext from "../../context/user.context";
import {simplify} from "../../utils/list.utils";
import ResponsePsy from "./psy/response.psy";
import {useHistory} from "react-router-dom";
import PercentPsy from "./psy/percent.psy";

const SectionPsy = ({user: userSelected}) => {
	const [responseData, setResponseData] = useState(null)
	const [questionnaire, setQuestionnaire] = useState(null)
	const [questionsList, setQuestionsList] = useState(null)

	const {user} = useContext(UserContext)
	const history = useHistory();

	useEffect(()=>{
		getResponse(userSelected.userId)
			.then(setResponseData)
			.catch(()=>{history.push(user.config.isAdmin ? "/clients" : "/questionnaire")})

	}, [])
	useEffect(()=>{
		if(responseData === null || responseData === undefined)
			return

		firebase.firestore()
			.collection("Questionnaire")
			.doc(responseData.questionnaireDocId)
			.get()
			.then(doc=>doc.data())
			.then(setQuestionnaire)

		firebase.firestore()
			.collection("Questionnaire")
			.doc(responseData.questionnaireDocId)
			.collection("Question")
			.get()
			.then((snap)=>{setQuestionsList(snap.docs.map(doc=>doc.data()))})
	}, [responseData])

	if(responseData === null || questionsList === null || questionnaire === null)
		return <MDBSpinner/>

	return !user.config.isAdmin ? (
		<SectionPsyAdmin
			questionnaire={questionnaire}
			questionsList={questionsList}
			responses={responseData.responses}
		/>
	) : (
		<SectionPsyCustomer
			questionnaire={questionnaire}
			questionsList={questionsList}
			responses={responseData.responses}
		/>
	)
}

const SectionPsyAdmin = ({questionnaire, questionsList, responses}) => {
	return(
		<React.Fragment>
			<RadarPsy
				questionsList={questionsList}
				attributesList={questionnaire.attributesList}
				responses={responses}
			/>

			<PercentPsy
				directionsList={simplify(questionnaire.attributesList.map(attribute => attribute.direction))}
				questionsList={questionsList}
				responses={responses}
			/>

			<ResponsePsy
				directionsList={simplify(questionnaire.attributesList.map(attribute => attribute.direction))}
				attributesList={questionnaire.attributesList}
				questionsList={questionsList}
				responses={responses}
			/>
		</React.Fragment>
	)
}

const SectionPsyCustomer = ({questionnaire, questionsList, responses}) => {
	return(
		<React.Fragment>
			<RadarPsy
				questionsList={questionsList}
				attributesList={questionnaire.attributesList}
				responses={responses}
			/>

			<PercentPsy
				directionsList={simplify(questionnaire.attributesList.map(attribute => attribute.direction))}
				questionsList={questionsList}
				responses={responses}
			/>
		</React.Fragment>
	)
}

export default SectionPsy