import React from 'react'
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {useTheme} from "@material-ui/core";

import callMe from '../../assets/img/svg/call-now.svg'
import logoPatrickRobertoWhite from '../../assets/img/logo-patrick-roberto-ecriture-white.png'

const FooterLayout = () => {
	const theme = useTheme()
	return(
		<footer style={{
			color: "white",
			backgroundColor: "#161616",
			paddingTop: "1rem",
			paddingBottom: "1rem",
			bottom: 0,
			marginTop: "2rems",
			width: "100%"
		}}>
			<div style={{
				borderBottomStyle: "solid",
				borderBottomColor: theme.palette.primary.main,
				borderBottomWidth: "1px"
			}}>
				<MDBRow center className="mr-0 ml-0">
					<MDBCol size="2" style={{marginRight: "5rem"}}>
						<img src={logoPatrickRobertoWhite} alt="logo-white"/>

						<p className="text-justify">
							Etant coach à plein temps, mon objectif est d’aider les gens à combattre leurs peurs et leurs problèmes de vie.
						</p>
					</MDBCol>

					<MDBCol size="2" className="mr-4 text-justify">
						<h4><b>Informations</b></h4>
						<MDBIcon icon="map-marker" style={{color: theme.palette.primary.main}} className="mr-1"/> 12 rue de Versailles, 75000 Paris
						<br/><br/>
						<MDBIcon icon="envelope" style={{color: theme.palette.primary.main}} className="mr-1"/> signature@roberto.coach
						<br/><br/>
						<p>
							Lundi – Vendredi (9h00 – 18h00)<br/>
							Samedi et dimanche : FERME
						</p>
					</MDBCol>

					<MDBCol size="2" className="mr-4 text-justify">
						<h4><b>Réservations</b></h4>

						<img src={callMe} alt="call-me" style={{paddingTop: "1rem", paddingBottom: "1rem", width: "70%"}}/>

						<p>
							Pour vos demandes de réservations<br/>
							(disponible de 9h00 à 19h00)
						</p>
					</MDBCol>
				</MDBRow>
			</div>

			<MDBRow center className="mr-0 ml-0 pt-3 pb-1">
				<MDBCol size="4">
					Copyright © 2021 Patrick Roberto
				</MDBCol>

				<MDBCol size="3">
					<a href="https://www.patrickroberto.com/mentions-legales/" className="menu-link mr-3">Mentions Légales</a>
					<a href="https://www.patrickroberto.com/politique-de-confidentialite/" className="menu-link">Politique de confidentialité</a>
				</MDBCol>
			</MDBRow>
		</footer>
	)
}

export default FooterLayout