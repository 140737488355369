import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {MDBCol, MDBRow, MDBSpinner} from "mdbreact";

import firebase from './api/firebase.api.js'

import HomePage from './pages/home.page'
import LoginPage from './pages/login.page'
import SignupPage from './pages/signup.page';
import HeaderLayout from './components/layout/header.layout'
import FooterLayout from './components/layout/footer.layout'

import './assets/css/app.css';
import * as UserModel from "./model/user.model";

import ToastContext from "./context/toast.context";
import UserContext from "./context/user.context"

import ConnectedRouter from "./routes/connected.router";
import AccountDialog from "./components/account.dialog";

export default function App() {
    const [user, setUser] = useState(undefined)
    const [openAccountDialog, setOpenAccountDialog] = useState(false)
    const location = useLocation()

    const {toast} = useContext(ToastContext)

    useEffect(()=>{
        const unsubscribe = firebase.auth().onAuthStateChanged((userRecord)=>{
            if(userRecord !== null) {
                UserModel.get(userRecord.uid)
                    .then(setUser)
                    .catch((errorMessage) => {
                        toast.fire({icon: "error", title: errorMessage})
                        firebase.auth().signOut();
                    })
            } else {
                setUser(null)
            }
        })
        return () => {
            unsubscribe();
        };
    }, [toast])

    const onlyNotConnectedPathname = ["/connexion", "/inscription"]

    if(user === undefined)
        return <MDBSpinner/>

    if(user !== null && onlyNotConnectedPathname.includes(location.pathname))
        return <Redirect to="/"/>

    if(user === null && !onlyNotConnectedPathname.includes(location.pathname))
        return <Redirect to="/connexion"/>

    return(
        <React.Fragment>
            <UserContext.Provider value={{user, setUser}}>
                <HeaderLayout handleOpenAccountDialog={()=>{setOpenAccountDialog(true)}}/>

                <main style={{minHeight: "55vh"}}>
                    <MDBRow center={true} className="mx-0">
                        <MDBCol size="8">
                            <Switch>
                                <Route path='/connexion' exact component={LoginPage} />
                                <Route path='/inscription' exact component={SignupPage} />
                                <Route path='/' exact component={HomePage}/>
                                <ConnectedRouter/>
                            </Switch>
                        </MDBCol>
                    </MDBRow>
                </main>

                {user !== null && (
                    <AccountDialog
                        open={openAccountDialog}
                        handleClose={()=>{setOpenAccountDialog(false)}}
                    />
                )}

                <FooterLayout/>
            </UserContext.Provider>
        </React.Fragment>
    );
}