import Swal from 'sweetalert2';

const error = (error) => {
    let options = {
        icon: "error",
        title: "Oups une erreur est survenue",
        showCancelButton: false
    }

    if(error.response && error.response.data.error){
        options = {...options, "text": error.response.data.error}
    } else if(error.response && error.response.data.sqlMessage) {
        options = {...options, "text": error.response.data.sqlMessage}
    } else if(typeof(error) === "string") {
        options = {...options, "text": error}
    }

    return Swal.fire(options);
}

const warningDelete = () => {
    const swalOptions = {
        title: 'Êtes-vous sûr ?',
        text: 'Vous ne pourrez pas revenir en arrière par la suite',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: "Annuler"
    };

    return new Promise((resolve => {
        Swal.fire(swalOptions).then((result) => {
            if (result.value) {
                resolve()
            }
        })
    }))
}

export {
    error,
    warningDelete
}