import React, {useContext, useEffect, useState} from 'react'
import ToastContext from "../context/toast.context";
import * as UserModel from "../model/user.model";
import {Button} from "@material-ui/core";
import {MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import {Alert} from "@material-ui/lab";
import {useHistory} from "react-router-dom";

const LoginPage = () => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [isValidate, setIsValidate] = useState(false)
	const [error, setError] = useState("")

	const [isPending, setIsPending] = useState(false)

	const {toast} = useContext(ToastContext)
	const history = useHistory();

	useEffect(()=>{
		setIsValidate(email!=="" && password!=="")
	}, [email, password])

	const handleSubmit = (event) =>{
		event.preventDefault();
		setIsPending(true)
		UserModel.login(email, password)
			.then(()=>{
				toast.fire({icon: "success", title: "Vous êtes connecté"})
				history.push('/')
			})
			.catch(setError)
			.finally(()=>{setIsPending(false)})
	}

	return(
		<section id="login-content">
			<MDBContainer>
				<MDBRow className="mt-5 mb-5 mx-0" center={true}>
					<MDBCol md="5" className="container" style={{alignItems: "center", display: "grid"}}>
						{error !== "" && (
							<div style={{marginTop: "2rem", marginBottom: "2rem"}}>
								<Alert variant="outlined" severity="error">
									{error}
								</Alert>
							</div>
						)}
						<form onSubmit={handleSubmit}>
							<h2 style={{textAlign: "center", fontWeight: "500"}}>Connexion</h2>
							<br/>

							<MDBInput
								focused
								size="lg"
								label="Adresse e-mail"
								getValue={setEmail}
								icon="envelope"
								value={email}
								iconLight={true}
								type="email"
								style={{fontSize: "1.2rem"}}
							/>

							<MDBInput
								size="lg"
								value={password}
								getValue={setPassword}
								label="Mot de passe"
								icon="lock"
								iconLight={true}
								type="password"
								style={{fontSize: "1.2rem"}}
							/>

							<div className="text-center" style={{marginTop: "2rem"}}>
								<Button
									variant="contained"
									type="submit"
									color="primary"
									disabled={!isValidate}
									className="mt-3"
									size="large"
								>
									Connexion {isPending && <MDBIcon icon="spinner" pulse className="ml-1" size="1x" fixed />}
								</Button>
							</div>
						</form>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</section>
	);
}

export default LoginPage