import {MDBCol, MDBRow} from "mdbreact";
import React from "react";

const NumRow = ({text, detail, result}) => {
	let detailsReformatted = detail.join(" = ")
		.replaceAll("0 +", "")
		.replaceAll("0 +", "")
		.replaceAll(" ", "")

	return(
		<MDBRow style={{marginTop: "0.7rem"}}>
			<MDBCol size="5">
				{text}
			</MDBCol>
			<MDBCol size="7" className="text-right">
				{detailsReformatted} {detail.length !== 0 && "="} chiffre {result}
			</MDBCol>
		</MDBRow>
	)
}

export default NumRow