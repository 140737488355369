import React from "react";
import NumRow from "./row.num";

const CyclesDetail = ({cyclesData}) => (
	<div
		id="result-cycles-detail"
		style={{marginTop: "1rem", marginBottom: "1rem", fontSize: "1.3rem", fontWeight: "500"}}
	>
		<NumRow
			text={"Premier cycle (mois de naissance):"}
			detail={cyclesData.first.details}
			result={cyclesData.first.calc}
		/>
		<NumRow
			text={"Deuxième cycle (jour de naissance):"}
			detail={cyclesData.second.details}
			result={cyclesData.second.calc}
		/>
		<NumRow
			text={"Troisième cycle (année de naissance):"}
			detail={cyclesData.third.details}
			result={cyclesData.third.calc}
		/>
	</div>
)

export default CyclesDetail