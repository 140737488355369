import React from "react";
import NumRow from "./row.num";

const RealisationsDetail = ({realisationsData}) => (
	<div
		id="result-realisation-detail"
		style={{marginTop: "1rem", marginBottom: "1rem", fontSize: "1.3rem", fontWeight: "500"}}
	>
		<NumRow
			text={"Première réalisation (jour + mois):"}
			detail={realisationsData.first.details}
			result={realisationsData.first.calc}
		/>
		<NumRow
			text={"Deuxième réalisation (jour + année):"}
			detail={realisationsData.second.details}
			result={realisationsData.second.calc}
		/>
		<NumRow
			text={"Troisième réalisation (1ère + 2ème réalisation):"}
			detail={realisationsData.third.details}
			result={realisationsData.third.calc}
		/>
	</div>
)

export default RealisationsDetail