import React, {useEffect, useState} from 'react'
import {MDBAnimation, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";

import * as UserModel from '../model/user.model'
import * as ListUtils from "../utils/list.utils";

import FilterSearch from "../components/search.input";
import CustomerRow from "../components/customer.row";

const CustomersListPage = () => {
	const [customersList, setCustomersList] = useState([])
	const [customersFilteredList, setCustomersFilteredList] = useState([])

	const [filter, setFilter] = useState("")

	useEffect(()=>{
		UserModel
			.getCustomersRef()
			.onSnapshot((snap)=>{
				const localCustomersList = snap.docs
					.map(doc=>doc.data())
					.map(data=> {
						return {...data, completeName: `${data.firstname} ${data.lastname}`};
					});

				setCustomersList(localCustomersList);
			})
	}, [])
	useEffect(()=>{
		if(filter === "")
			setCustomersFilteredList(customersList)

		setCustomersFilteredList(
			ListUtils.filterSearch(customersList,"completeName", filter)
		)
	}, [customersList, filter])

	return(
		<section id="admin-list-customers">
			<h2 style={{fontWeight: "500"}} className="mt-4">
				Mes clients
			</h2>

			<MDBRow className="mt-3">
				<MDBCol size="6">
					<FilterSearch
						placeholder={"Rechercher"}
						handle={(value)=>{setFilter(value)}}
						value={filter}
					/>
				</MDBCol>
			</MDBRow>

			<div className="table-content mt-3" style={{backgroundColor: "white"}}>
				<MDBAnimation type="fadeIn">
					<MDBTable className="table-head-bold" scrollY={true} maxHeight={"65vh"}>
						<MDBTableHead>
							<tr>
								<th style={{paddingLeft: "2rem"}}>Nom complet</th>
								<th>Email</th>
								<th>Status du paiement</th>
								<th>Questionnaire réalisé le</th>
								<th>Action</th>
							</tr>
						</MDBTableHead>
						<MDBTableBody>
							{customersFilteredList.map((customer, index)=>(
								<CustomerRow
									customer={customer}
									key={index}
								/>
							))}
						</MDBTableBody>
					</MDBTable>
				</MDBAnimation>
			</div>

		</section>
	)
}

export default CustomersListPage