import React, {useContext, useState} from 'react';

import {MDBIcon} from "mdbreact";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as UserModel from '../model/user.model';

import firebase from "firebase";
import {isEmail} from "../utils/string.utils";

import ToastContext from "../context/toast.context";
import UserContext from "../context/user.context"
import {error} from "../utils/alert.utils";

export default function AccountDialog({open, handleClose}) {
	const [email, setEmail] = useState(firebase.auth().currentUser.email)

	const [password, setPassword] = useState( "")
	const [repassword, setRepassword] = useState("")

	const [isPending, setIsPending] = useState(false)

	const {user} = useContext(UserContext)
	const {toast} = useContext(ToastContext);

	const handleSubmit = async () => {
		setIsPending(true)
		try {
			if(email !== firebase.auth().currentUser.email){
				await firebase.auth().currentUser.updateEmail(email)
			}

			if(password !== ""){
				await firebase.auth().currentUser.updatePassword(email)
			}

			await UserModel.update(user.userId, {
				email
			});


			toast.fire({icon: "success", title: "Compte modifiée"})
			handleClose()
		} catch (errorString){
			error(errorString)
		} finally {
			setIsPending(false)
		}

	}

	const isValid = () => {
		const currentUser = firebase.auth().currentUser

		const notEmpty = isEmail(email);
		const hasBeenChanged = email !== currentUser.email 	|| (password !== "" && password === repassword);

		return notEmpty && hasBeenChanged
	}

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Éditer mon compte</DialogTitle>
			<DialogContent>
				<TextField
					onChange={(event)=>{setEmail(event.target.value)}}
					value={email}
					autoFocus
					margin="dense"
					label="Adresse e-mail"
					type="email"
					fullWidth
					className="mb-3"
				/>
				<TextField
					onChange={(event)=>{setPassword(event.target.value)}}
					value={password}
					margin="dense"
					label="Mot de passe (laisser le champ vide pour ne pas le changer)"
					type="password"
					fullWidth
				/>
				<TextField
					onChange={(event)=>{setRepassword(event.target.value)}}
					value={repassword}
					margin="dense"
					label="Confirmation du mot de passe"
					type="password"
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid()}>
					Modifier
					{isPending && <MDBIcon icon="spinner" pulse className="ml-1" size="1x" fixed />}
				</Button>
			</DialogActions>
		</Dialog>
	);
}