import React, {useEffect, useState} from 'react'

import * as UserModel from "../model/user.model";

import {Button} from "@material-ui/core";
import {MDBCol, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Alert} from "@material-ui/lab";

import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";

moment.locale("fr");

const SignupPage = () => {
	const [firstname, setFirstname] = useState("")
	const [lastname, setLastname] = useState("")

	const [birthdayDateTime, setBirthdayDateTime] = useState(new Date())

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [confirmationPassword, setConfirmationPassword] = useState("")

	const [isValidate, setIsValidate] = useState(false)
	const [error, setError] = useState("")

	const [isPending, setIsPending] = useState(false)

	useEffect(()=>{
		const notEmpty = email !== "" && password !== "" && confirmationPassword !== "" && firstname !== "" && lastname !== "" && birthdayDateTime !== "";
		setIsValidate(notEmpty && password === confirmationPassword)
	}, [email, password])

	const handleSubmit = (event) =>{
		const userData = {
			email: email,
			password: password,
			firstname: firstname,
			lastname: lastname,
			birthdayDateTime: birthdayDateTime
		}

		event.preventDefault();
		setIsPending(true)
		UserModel.create(userData)
			.then((userId)=>{
				setIsPending(false);
				//go to stripe URL `?userId=${userId}`
			})
			.catch(setError)
	}

	return(
		<section id="signup-content">
			<MDBRow className="mt-5 mb-5" center={true}>
				<MDBCol md="6" className="container">
					{error !== "" && (
						<div style={{marginTop: "2rem", marginBottom: "2rem"}}>
							<Alert variant="outlined" severity="error">
								{error}
							</Alert>
						</div>
					)}

					<form onSubmit={handleSubmit} className="mt-2">
						<h2 style={{textAlign: "center", fontWeight: "500"}}>Inscription</h2>
						<br/>

						<MDBRow center={true}>
							<MDBCol md="5" className="container">
								<MDBInput
									required
									focused
									size="md"
									label="Prénom"
									getValue={setFirstname}
									value={firstname}
									type="text"
									style={{fontSize: "1.2rem"}}
								/>
							</MDBCol>
							<MDBCol md="5" className="container">
								<MDBInput
									required
									size="md"
									label="Nom"
									getValue={setLastname}
									value={lastname}
									type="text"
									style={{fontSize: "1.2rem"}}
								/>
							</MDBCol>
						</MDBRow>

						<MuiPickersUtilsProvider utils={MomentUtils}>
							<MDBRow center={true}>
								<MDBCol md="5" className="container">
									<KeyboardDatePicker
										required
										openTo="year"
										id="date-picker-dialog"
										label="Date de naissance"
										format="DD/MM/YYYY"
										value={birthdayDateTime}
										onChange={setBirthdayDateTime}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
								</MDBCol>
								<MDBCol md="5" className="container">
									<KeyboardTimePicker
										id="time-picker"
										label="Heure de naissance"
										value={birthdayDateTime}
										onChange={setBirthdayDateTime}
										KeyboardButtonProps={{
											'aria-label': 'change time',
										}}
									/>
								</MDBCol>
							</MDBRow>
						</MuiPickersUtilsProvider>

						<MDBInput
							required
							size="md"
							label="Adresse e-mail"
							getValue={setEmail}
							value={email}
							type="email"
							style={{fontSize: "1.2rem"}}
							containerClass="mx-4"
						/>

						<MDBInput
							required
							size="md"
							value={password}
							getValue={setPassword}
							label="Mot de passe"
							type="password"
							style={{fontSize: "1.2rem"}}
							containerClass="mx-4"
						/>

						<MDBInput
							required
							size="md"
							value={confirmationPassword}
							getValue={setConfirmationPassword}
							label="Confirmer le mot de passe"
							type="password"
							style={{fontSize: "1.2rem"}}
							containerClass="mx-4"
						/>

						<div className="text-center" style={{marginTop: "2rem"}}>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								disabled={isValidate}
								className="mt-3"
								size="large"
							>
								Créer mon compte {isPending && <MDBIcon icon="spinner" pulse className="ml-1" size="1x" fixed />}
							</Button>
						</div>
					</form>
				</MDBCol>
			</MDBRow>
		</section>
	);
}

export default SignupPage