import React from "react";
import {InputBase, Paper} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0.6rem 0.35rem',
	},
	input: {
		width: "90%",
		marginLeft: theme.spacing(1)
	},
	icon: {
		padding: "1rem",
	},
}));

export default function FilterSearch({placeholder, handle, value}){
	const classes = useStyles();

	return(
		<Paper component="div" className={classes.root}>
			<SearchIcon />
			<InputBase
				className={classes.input}
				placeholder={placeholder}
				value={value}
				onChange={(event)=>{handle(event.target.value)}}
			/>
		</Paper>
	)
}