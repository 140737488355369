import React, {useContext, useEffect, useState} from 'react'
import firebase from '../api/firebase.api'
import UserContext from "../context/user.context";

import {useHistory, useLocation} from "react-router-dom";

const QuestionnaireEndPage = () => {
	const [questionnaireDone, setQuestionnaireDone] = useState(null)

	let query = useQuery();

	const {user} = useContext(UserContext)
	const history = useHistory();

	useEffect(()=>{
		firebase
			.firestore()
			.collection("User")
			.doc(user.userId)
			.get()
			.then((doc)=>{
				setQuestionnaireDone(doc.exists)
			})
	})

	if(!questionnaireDone)
		history.push(`/questionnaire`)

 	return(
		<section id="questionnaire-end" className="text-center mb-4">
			Fin du questionnaire
			<br/>
			{query.has("cond") && query.get("cond") === "time" ? (
				<h2>
					Vous n'avez pas fini le questionnaire dans les temps
				</h2>
			) : ""}
			<br/>
			Merci de prendre rendez-vous avec le coach
		</section>
	)
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default QuestionnaireEndPage