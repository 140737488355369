import React, {useContext, useEffect, useState} from 'react'
import * as QuestionsModel from '../model/questions.model'
import QuestionRow from "../components/question/question.row";
import {Button} from "@material-ui/core";
import QuestionnaireHeader from "../components/question/header.question.s";
import {MDBSpinner} from "mdbreact";
import * as StringUtils from "../utils/string.utils";
import UserContext from "../context/user.context";
import {useHistory} from "react-router-dom";

const QuestionnairePage = () => {
	const [questionGroupIndex, setQuestionGroupIndex] = useState(46)
	const [questionGroupsList, setQuestionGroupsList] = useState([[]])

	const [maxTime, setMaxTime] = useState(null)
	const [key, setKey] = useState(null)

	const [responseDocId, setResponseDocId] = useState(null)
	const [responseMap, setResponseMap] = useState({})

	const [time, setTime] = useState(0)

	const history = useHistory();
	const {user} = useContext(UserContext)

	useEffect(()=>{
		QuestionsModel
			.getQuestionnaire()
			.then(({questionnaireData, questionList})=>{
				setMaxTime(questionnaireData.maxTime)
				setKey(questionnaireData.key)
				setQuestionGroupsList(questionList)
			})
	}, [])
	useEffect(()=>{
		if(key === null)
			return;

		setResponseDocId(
			`Response-${key}-${StringUtils.generateString(4)}`
		)
	}, [key])
	useEffect(()=>{
		if(maxTime === null)
			return

		let time = 0;
		const intervalId = setInterval(() => {
			time++;

			if(time > maxTime)
				clearInterval(intervalId)

			setTime(time)
		}, 1000)

		return () => {
			clearInterval(intervalId)
		};

	}, [maxTime])
	useEffect(()=>{
		if(maxTime === null)
			return

		if(time < maxTime)
			return;

		const promiseSave =	Object.keys(responseMap).length === 5 ?
			QuestionsModel.createResponse(responseMap,"Questionnaire-psy-0001", responseDocId, user.userId) :
			QuestionsModel.updateResponse(responseMap, responseDocId, user.userId);

		Promise.all([
			promiseSave,
			QuestionsModel.finishQuestionnaire(maxTime, responseDocId, user.userId)
		]).then(()=>{
			history.push(`questionnaire/fin?cond=temps`)
		})
	}, [maxTime, time])

	const handleResponse = (questionId, responseValue) => {
		setResponseMap({...responseMap, [questionId]: responseValue})
	}
	const handleNext = async () => {
		if(Object.keys(responseMap).length === 5){
			await QuestionsModel.createResponse(responseMap,"Questionnaire-psy-0001", responseDocId, user.userId)
		} else {
			await QuestionsModel.updateResponse(responseMap, responseDocId, user.userId);
		}

		if(questionGroupIndex === 47){
			await QuestionsModel.finishQuestionnaire(time, responseDocId, user.userId)
			history.push(`/questionnaire/fin`)
			return
		}

		setQuestionGroupIndex(questionGroupIndex+1)
	}

	if(questionGroupsList[0].length === 0 || maxTime === null)
		return <MDBSpinner/>

 	return(
		<section id="questionnaire-list" className="text-center mb-4">
			<QuestionnaireHeader
				actual={questionGroupIndex}
				length={questionGroupsList.length}
				time={time}
				maxTime={maxTime}
			/>

			{questionGroupsList[questionGroupIndex].map((question, indexGroup) => (
				<QuestionRow
					response={responseMap[question.id]}
					handleResponse={handleResponse}
					question={question}
					key={indexGroup}
				/>
			))}

			<Button
				style={{
					width: "15vw"
				}}
				className="mt-3"
				variant="contained"
				disabled={Object.entries(responseMap).length !== (questionGroupIndex+1)*5}
				color="primary"
				size="large"
				onClick={handleNext}
			>
				{questionGroupIndex === 47 ? "Terminer" : "Suivant"}
			</Button>
		</section>
	)
}

export default QuestionnairePage