import React, {useContext, useEffect, useState} from 'react'
import {useParams} from "react-router-dom";

import {MDBSpinner} from "mdbreact";
import {makeStyles} from "@material-ui/core/styles";

import firebase from '../api/firebase.api'

import UserContext from "../context/user.context";

import Header from "../components/result/header.result";
import SectionNum from "../components/result/num";

import SectionPsy from "../components/result/psy";

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: "2rem",
		fontWeight: "500",
		color: theme.palette.primary.main,
		marginTop: "1rem"
	},
	subtitle: {
		fontSize: "1.3rem",
		fontWeight: "400",
		color: theme.palette.primary.main,
		marginTop: "0.6rem"
	},
}));

const ResultPage = () => {
	const {user} = useContext(UserContext)
	const {userId} = useParams();
	const classes = useStyles();

	const [userResult, setUserResult] = useState(userId === undefined ? user : null)

	useEffect(()=>{
		if(userId === undefined)
			return;

		firebase
			.firestore()
			.collection("User")
			.doc(userId)
			.get()
			.then((doc)=>{
				const data = doc.data();
				setUserResult(data);
			})
	}, [userId])

	if(userId !== undefined && userResult === null)
		return <MDBSpinner/>

	return(
		<section id="result-page">
			<Header user={userResult}/>

			<h1 className={classes.title}>
				<u>Partie numérologie :</u>
			</h1>
			<SectionNum user={user}/>

			<h1 className={classes.title}>
				<u>Partie PSY :</u>
			</h1>
			<SectionPsy user={userResult}/>
		</section>
	)
}

export default ResultPage