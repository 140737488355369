import React, {useContext, useEffect, useState} from 'react'
import {PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart} from 'recharts';
import UserContext from "../../../context/user.context";

const RadarPsy = ({attributesList, questionsList, responses}) => {
	const [radarData, setRadarData] = useState([])

	const {user} = useContext(UserContext)

	useEffect(()=>{
		let localRadarData = attributesList.map(attribute => {
			const questionIdCategory = questionsList
				.filter(question => question.category === attribute.category)
				.map(question => question.id)

			const responseCategory = Object.keys(responses)
				.filter(key => questionIdCategory.includes(key))
				.map(key => parseInt(responses[key]))

			const calcCategory = responseCategory.length === 0 ?
				0 :
				responseCategory.reduce((accumulator, currentValue) => accumulator + currentValue)

			return { ...{
					subject: attribute.category,
					AFFECTIVITE: 0,
					COGNITION: 0,
					NOUVEAUTE: 0,
					REALITE: 0,
					fullMark: 100,
				}, [attribute.direction]: calcCategory}
		})
		setRadarData(localRadarData)
	}, [])
	useEffect(()=>{
		if(radarData === [])
			return

		setTimeout(()=>{
			const topLabel = document.querySelector(`#result-page > div:nth-child(${user.config.isAdmin ? 14 : 10}) > div > svg > g.recharts-layer.recharts-polar-angle-axis > g > g:nth-child(1) > text`)
			const bottomLabel = document.querySelector(`#result-page > div:nth-child(${user.config.isAdmin ? 14 : 10}) > div > svg > g.recharts-layer.recharts-polar-angle-axis > g > g:nth-child(13) > text`)

			if(topLabel !== null && bottomLabel !== null){
				topLabel.setAttribute("y", "90")
				bottomLabel.setAttribute("y", "860")
			}

		}, 500)
	}, [radarData])

	const radarWidth = (window.screen.width/12)*10
	const radarX = (window.screen.width/12)*4

	return(
		<div>
			<RadarChart
				cx={radarX}
				cy={475}
				outerRadius={350}
				width={radarWidth}
				height={900}
				data={radarData}
			>
				<PolarGrid />
				<PolarAngleAxis dataKey="subject" />
				<PolarRadiusAxis angle={0} domain={[0, 100]} />
				<Radar
					name="AFFECTIVITE"
					dataKey="AFFECTIVITE"
					stroke="#B4C6E7"
					fill="#B4C6E7"
					fillOpacity={0.8}
				/>
				<Radar
					name="COGNITION"
					dataKey="COGNITION"
					stroke="#92D050"
					fill="#92D050"
					fillOpacity={0.8}
				/>
				<Radar
					name="REALITE"
					dataKey="REALITE"
					stroke="#FFE699"
					fill="#FFE699"
					fillOpacity={0.8}
				/>
				<Radar
					name="NOUVEAUTE"
					dataKey="NOUVEAUTE"
					stroke="#FFFF00"
					fill="#FFFF00"
					fillOpacity={0.8}
				/>
			</RadarChart>
		</div>
	)
}

export default RadarPsy;