import firebase from '../api/firebase.api.js'
import {generateString} from "../utils/string.utils";

const auth_code_translate = {
	"auth/invalid-email": "Adresse e-mail invalide",
	"auth/user-disabled": "Utilisateur désactivé",
	"auth/user-not-found": "Aucun utilisateur ne correspond à cette adresse e-mail",
	"auth/wrong-password": "Mot de passe invalide"
}

const getFromUserId = (userId) => (
	firebase
		.firestore()
		.collection("User")
		.doc(userId)
		.get()
		.then(doc=>doc.data())
)


const get = (uid) => (
	firebase
		.firestore()
		.collection("User")
		.where("uid", "==", uid)
		.get()
		.then(queries=>queries.docs[0])
		.then(doc=>doc.data())
)

const update = (userId, data) => (
	firebase
		.firestore()
		.collection("User")
		.doc(userId)
		.update(data)
)

const getCustomersRef = () => (
	firebase
		.firestore()
		.collection("User")
		.where("config.isAdmin", "==", false)
)

const getResponses = (userId) => (
	firebase
		.firestore()
		.collection("User")
		.doc(userId)
		.collection("Response")
		.orderBy("dateTime", "desc")
		.get()
)

const login = (email, password) => (
	firebase
		.auth()
		.signInWithEmailAndPassword(email, password)
		.catch(error=>Promise.reject(auth_code_translate[error.code]))
)

export function create({email, password, firstname, lastname, birthdayDateTime}) {
	return firebase
		.auth()
		.createUserWithEmailAndPassword(email, password)
		.then((userCred)=>{
			const uid = userCred.user.uid;
			const userId = `User-${firstname}-${lastname}-${generateString(4)}`;

			return firebase
				.firestore()
				.collection("User")
				.doc(userId)
				.set({
					config: {
						isActive: false,
						resultAccess: false,
						isAdmin: false
					},
					email: email,
					firstname: firstname,
					lastname: lastname,
					uid: uid,
					userId: userId,
					birthdayDateTime: birthdayDateTime.unix() * 1000
				})
				.then(()=>Promise.resolve(userId))
		})
		.catch(error=>Promise.reject(auth_code_translate[error.code]))
}

export {
	get,
	login,
	update,
	getCustomersRef,
	getResponses,
	getFromUserId
}