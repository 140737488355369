const reduceCalc = (calc, details) => {
	if(calc.toString().length === 1){
		return {calc: calc, details: details}
	}

	const digitArray = (""+calc).split("")
	const newCalc = digitArray.reduce((result, digit) => parseInt(result) + parseInt(digit))

	details.push(`${digitArray.join(" + ")} = ${newCalc}`)

	return reduceCalc(newCalc, details)
}

const convertArray = [
	{values: ["a", "j", "s"]},
	{values: ["b", "k", "t"]},
	{values: ["c", "l", "u"]},
	{values: ["d", "m", "v"]},
	{values: ["e", "n", "w"]},
	{values: ["f", "o", "x"]},
	{values: ["g", "p", "y"]},
	{values: ["h", "q"]},
	{values: ["i", "r"]}
];
const vowelArray = ["a", "e", "i", "o", "u", "y"]

const calcNum = ({birthdayDate, firstname, lastname}) => {
	const calcLifePath = (birthday) => {
		let details = [];

		const dateString = new Intl.DateTimeFormat(
			"fr-FR",
			{day: "2-digit", month: "2-digit", year: "numeric"}
		).format(birthday)

		const dateArray = dateString.split("/");

		const dayArray = dateArray[0].split("")
		const monthArray = dateArray[1].split("")
		const yearArray = dateArray[2].split("")

		let lifePathCalc =
			parseInt(dayArray[0]) + parseInt(dayArray[1]) +
			parseInt(monthArray[0]) + parseInt(monthArray[1]) +
			parseInt(yearArray[0]) + parseInt(yearArray[1]) + parseInt(yearArray[2]) + parseInt(yearArray[3]);

		details.push(`${dayArray[0]} + ${dayArray[1]} + ${monthArray[0]} + ${monthArray[1]} + ${yearArray[0]} + ${yearArray[1]} + ${yearArray[2]} + ${yearArray[3]} = ${lifePathCalc}`);

		return reduceCalc(lifePathCalc, details)
	}
	const calcUnivYear = () => {
		let details = [];
		const year = new Date()
			.getFullYear()
			.toString()
			.split("");

		let univYearCalc = parseInt(year[0]) + parseInt(year[1]) + parseInt(year[2]) + parseInt(year[3]);
		details.push(`${year[0]} + ${year[1]} + ${year[2]} + ${year[3]} = ${univYearCalc}`);
		return reduceCalc(univYearCalc, details)
	}
	const calcPersonalYear = (birthday, univYear) => {
		let details = [];

		const dateString = new Intl.DateTimeFormat(
		"fr-FR",
		{day: "2-digit", month: "2-digit", year: "numeric"}
		).format(birthday).split("/")

		let day = dateString[0].split("");
		let month = dateString[1].split("");

		let personalYearCalc = parseInt(day[0]) + parseInt(day[1]) + parseInt(month[0]) + parseInt(month[1]) + univYear;
		details.push(
			`${day[0]} + ${day[1]} + ${month[0]} + ${month[1]} + ${univYear} = ${personalYearCalc}`
		);

		return reduceCalc(personalYearCalc, details)
	}
	const calcNamingDigit = (name) => {
		let details = [];

		let nameLowerCase = name.toLowerCase();

		const arrayLetterNumber = nameLowerCase
			.split("")
			.map((letter) => {
				return convertArray.findIndex((element)=>{
					return element.values.includes(letter)
				})
			})
			.map((value)=>value+1)

		let activeNumberCalc = arrayLetterNumber.reduce((result, digit) => parseInt(result) + parseInt(digit))
		details.push(`${arrayLetterNumber.join(" + ")} = ${activeNumberCalc}`);

		return reduceCalc(activeNumberCalc, details)
	}
	const calcPersonalDigit = (firstname, lastname) => {
		let details = []
		const completeName = `${firstname}${lastname}`.toLowerCase();

		const arrayLetterNumber = completeName
			.split("")
			.filter(letter=>vowelArray.includes(letter))
			.map((letter) => {
				return convertArray.findIndex((element)=>{
					return element.values.includes(letter)
				})
			})
			.map((value)=>value+1)

		let personalNumberCalc = arrayLetterNumber.reduce((result, digit) => parseInt(result) + parseInt(digit))
		details.push(`${arrayLetterNumber.join(" + ")} = ${personalNumberCalc}`);

		return reduceCalc(personalNumberCalc, details)
	}
	const calcRealisationDigit = (firstname, lastname) => {
		let details = []
		const completeName = `${firstname}${lastname}`.toLowerCase();

		const arrayLetterNumber = completeName
			.split("")
			.filter(letter=>!vowelArray.includes(letter))
			.map((letter) => {
				return convertArray.findIndex((element)=>{
					return element.values.includes(letter)
				})
			})
			.map((value)=>value+1)

		let personalNumberCalc = arrayLetterNumber.reduce((result, digit) => parseInt(result) + parseInt(digit))
		details.push(`${arrayLetterNumber.join(" + ")} = ${personalNumberCalc}`);

		return reduceCalc(personalNumberCalc, details)
	}
	const calcExpressionDigit = (firstnameDigit, lastnameDigit) => {
		let details = [];

		let expressionCalc = firstnameDigit + lastnameDigit;
		details.push(`${firstnameDigit} + ${lastnameDigit} = ${expressionCalc}`);
		return reduceCalc(expressionCalc, details)
	}

	return {
		lifePath: calcLifePath(birthdayDate),
		univYear: calcUnivYear(),
		personalYear: calcPersonalYear(birthdayDate, calcUnivYear().calc),
		activeDigit: calcNamingDigit(firstname),
		hereditaryDigit: calcNamingDigit(lastname),
		expressionDigit: calcExpressionDigit(calcNamingDigit(firstname).calc, calcNamingDigit(lastname).calc),
		personalDigit: calcPersonalDigit(firstname, lastname),
		realisationDigit: calcRealisationDigit(firstname, lastname)
	}
}

const calcCycles = (birthday) => {
	const calcFirstCycle = (month) => {
		let monthArray = month.split("");
		if(monthArray[0] === "0")
			return {calc: monthArray[1], details: []}

		return {calc: monthArray[0]+monthArray[1], details: [`${monthArray[0]} + ${monthArray[1]} = ${monthArray[0]+monthArray[1]}`]}
	}
	const calcSecondCycle = (day) => {
		let dayArray = day.split("");
		if(dayArray[0] === "0")
			return {calc: dayArray[1], details: []}

		return reduceCalc(dayArray[0]+dayArray[1], [`${dayArray[0]} + ${dayArray[1]} = ${dayArray[0]+dayArray[1]}`]);
	}
	const calcThirdCycle = (year) => {
		let yearArray = year.split("");
		const calc = yearArray[0]+yearArray[1]+yearArray[2]+yearArray[3];

		return reduceCalc(calc, [])
	}

	const dateString = new Intl.DateTimeFormat("fr-FR", {day: "2-digit", month: "2-digit", year: "numeric"})
		.format(birthday)
		.split("/")

	return {
		first: calcFirstCycle(dateString[1]),
		second: calcSecondCycle(dateString[0]),
		third: calcThirdCycle(dateString[2])
	}
}

const calcRealisations = (birthday) => {
	const calcFirstReal = (day, month) => {
		let details = [];

		let dayArray = day.split("")
		let monthArray = month.split("")

		const result = parseInt(dayArray[0]) + parseInt(dayArray[1]) + parseInt(monthArray[0]) + parseInt(monthArray[1]);

		details.push(
			`${dayArray.join(" + ")} + ${monthArray.join(" + ")} = ${result}`
		)

		return reduceCalc(result, details)

	}
	const calcSecondReal = (day, year) => {
		let details = [];

		let dayArray = day.split("")
		let yearArray = year.split("")

		const result =
			parseInt(dayArray[0]) + parseInt(dayArray[1]) +
			parseInt(yearArray[0]) + parseInt(yearArray[1]) + parseInt(yearArray[2]) + parseInt(yearArray[3]);

		details.push(
			`${dayArray.join(" + ")} + ${yearArray.join(" + ")} = ${result}`
		)

		return reduceCalc(result, details)
	}
	const calcThirdReal = (firstReal, secondReal) => {
		let details = [];

		const result = firstReal + secondReal;

		details.push(
			`${firstReal} + ${secondReal} = ${result}`
		)

		return reduceCalc(result, details)
	}

	const dateString = new Intl.DateTimeFormat("fr-FR", {day: "2-digit", month: "2-digit", year: "numeric"})
		.format(birthday)
		.split("/")

	const returnObject = {
		first: calcFirstReal(dateString[0], dateString[1]),
		second: calcSecondReal(dateString[0], dateString[2])
	};

	return {...returnObject, third: calcThirdReal(returnObject.first.calc, returnObject.second.calc)}
}

const calcBirthTable = (firstname, lastname) => {
	const completeName = `${firstname}${lastname}`.toLowerCase();

	const arrayLetterNumber = completeName
		.split("")
		.map((letter) => {
			return convertArray.findIndex((element)=>{
				return element.values.includes(letter)
			})
		})
		.map((value)=>value+1)

	return Array.from({length: 10}, (v, k) => {
		const nb = arrayLetterNumber.filter(letter => letter === k + 1).length
		return nb === undefined ? 0 : nb
	});
}

export {
	calcNum,
	calcCycles,
	calcRealisations,
	calcBirthTable
}