import React, {useContext, useEffect, useState} from "react";
import BirthTable from "./num/birthtable.num";
import DetailsNum from "./num/details.num";
import CyclesDetail from "./num/cycles.num";

import {makeStyles} from "@material-ui/core/styles";

import UserContext from "../../context/user.context";
import {calcCycles, calcNum, calcRealisations} from "../../model/numerologie.model";
import RealisationsDetail from "./num/realisations.num";
import {MDBSpinner} from "mdbreact";
import FirebaseStorageImage from "../firebase.storage.image";

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: "2rem",
		fontWeight: "500",
		color: theme.palette.primary.main,
		marginTop: "1rem"
	},
	subtitle: {
		fontSize: "1.3rem",
		fontWeight: "400",
		color: theme.palette.primary.main,
		marginTop: "1rem"
	},
	subsubtitle: {
		fontSize: "1rem",
		fontWeight: "400",
		color: theme.palette.primary.main,
		marginTop: "1rem"
	}
}));

const SectionNum = ({user: userSelected}) => {
	const [numResult, setNumResult] = useState(null)

	const {user} = useContext(UserContext)

	useEffect(()=>{
		setNumResult({
			num: calcNum({
				birthdayDate: new Date(userSelected.birthdayDateTime),
				firstname: userSelected.firstname,
				lastname: userSelected.lastname
			}),
			cycles: calcCycles(new Date(userSelected.birthdayDateTime)),
			realisations: calcRealisations(new Date(userSelected.birthdayDateTime))
		})
	}, [userSelected])

	if(numResult === null)
		return <MDBSpinner/>

	return user.config.isAdmin ? (
		<SectionNumAdmin
			user={userSelected}
			realisations={numResult.realisations}
			cycles={numResult.cycles}
			num={numResult.num}
		/>
	) : (
		<SectionNumCustomer
			user={userSelected}
			num={numResult.num}
		/>
	)
}

const SectionNumCustomer = ({user, num}) => {
	const classes = useStyles();
	const [numWithoutDetail, setNumWithoutDetail] = useState(null);

	useEffect(()=>{
		let localNumWithoutDetail = {};

		for (const key of Object.keys(num)){
			const contentObject = num[key].hasOwnProperty("details") ? {
				calc: num[key].calc,
				details: num[key].details.map((detail) => detail.split("=")[1])
			} : num[key];
			localNumWithoutDetail = {...localNumWithoutDetail, [key]: contentObject};
		}

		setNumWithoutDetail(localNumWithoutDetail);
	}, [num])

	if(numWithoutDetail === null)
		return <MDBSpinner/>

	return(
		<React.Fragment>
			<DetailsNum
				numData={numWithoutDetail}
			/>

			<h2 className={classes.subtitle}>
				<u>Tableau de Naissance :</u>
			</h2>
			<BirthTable
				firstname={user.firstname}
				lastname={user.lastname}
			/>

			<h2 className={classes.subtitle}>
				<u>Tableau du Chemin de vie :</u>
			</h2>

			<FirebaseStorageImage
				src={"tableCyclesStorageRef" in user ? user.tableCyclesStorageRef : `Numerology/${user.userId}/table_cycles.png`}
				alt="table-cycles"
				style={{width: "80%"}}
			/>

			<FirebaseStorageImage
				src={"tableRealisationsStorageRef" in user ? user.tableRealisationsStorageRef : `Numerology/${user.userId}/table_realisations.png`}
				alt="table-realisations"
				style={{width: "80%"}}
			/>
		</React.Fragment>
	)
}

const SectionNumAdmin = ({user, realisations, cycles, num}) => {
	const classes = useStyles();

	return(
		<React.Fragment>
			<h2 className={classes.subtitle}>
				<u>Tableau de Naissance :</u>
			</h2>

			<DetailsNum numData={num}/>
			<BirthTable firstname={user.firstname} lastname={user.lastname}/>

			<h2 className={classes.subtitle}>
				<u>Tableau de vie des cycles et réalisations :</u>
			</h2>

			<h2 className={classes.subsubtitle}>
				<u>Calcul des 3 cycles:</u>
			</h2>
			<CyclesDetail cyclesData={cycles}/>
			<FirebaseStorageImage
				src={"tableCyclesStorageRef" in user ? user.tableCyclesStorageRef : `Numerology/${user.userId}/table_cycles.png`}
				alt="table-cycles"
				style={{width: "80%"}}
			/>

			<h2 className={classes.subsubtitle}>
				<u>Calcul des 4 realisations:</u>
			</h2>
			<RealisationsDetail realisationsData={realisations}/>
			<FirebaseStorageImage
				src={"tableRealisationsStorageRef" in user ?	user.tableRealisationsStorageRef : `Numerology/${user.userId}/table_realisations.png`}
				alt="table-realisations"
				style={{width: "80%"}}
			/>
		</React.Fragment>
	)
}

export default SectionNum