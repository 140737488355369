import React, {useContext} from "react";
import {useHistory} from "react-router-dom";

import {FormControlLabel, IconButton, Switch, useTheme} from "@material-ui/core";
import {MDBCol, MDBRow} from "mdbreact";

import ToastContext from "../../context/toast.context";
import firebase from "../../api/firebase.api";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import UserContext from "../../context/user.context";

const Header = ({user: userSelected}) => {
	const {user} = useContext(UserContext)

	return(
		<div id="result-header" style={{marginTop: "0.5rem"}}>
			{user.config.isAdmin && <ResultHeaderAction user={userSelected}/>}

			<div id="result-user-data" style={{fontSize: "1rem", fontWeight: "500"}}>
				<ProfilSpan text={"Prénom: "} value={userSelected.firstname}/>
				<ProfilSpan text={"Nom: "} value={userSelected.lastname}/>
				<ProfilSpan text={"Date de naissance: "} value={new Intl.DateTimeFormat("fr-FR").format(userSelected.birthdayDateTime)}/>
				<ProfilSpan text={"Heure de naissance (facultative): "} value={new Intl.DateTimeFormat("fr-FR", {
					hour: 'numeric', minute: 'numeric', second: 'numeric'
				}).format(userSelected.birthdayDateTime)}/>
			</div>
		</div>
	)
}

const ProfilSpan = ({text, value}) => (
	<div style={{marginTop: "1rem"}}>
		<span style={{fontWeight: "400"}}>{text}</span>{value}<br/>
	</div>
)

const ResultHeaderAction = ({user}) => {
	const history = useHistory();
	const theme = useTheme();
	const {toast} = useContext(ToastContext)

	const handleBack = () => {
		history.push('/clients')
	}
	const handleResultAccess = (event) => {
		let config = {...user.config, haveResultAccess: event.target.checked}
		firebase
			.firestore()
			.collection("User")
			.doc(user.userId)
			.update({
				config: config
			})
			.then(()=>{
				toast.fire({
					icon: "success",
					title: "Le client a été autorisé"
				})
			})
	}

	return(
		<MDBRow id="result-header-action">
			<MDBCol size="9">
				<h4 style={{fontSize: "1.7rem", fontWeight: "600", color: theme.palette.primary.main}}>
					<IconButton color="primary" aria-label="back" onClick={handleBack}>
						<ArrowBackIcon fontSize="large" />
					</IconButton>
					Liste des clients
				</h4>
			</MDBCol>

			<MDBCol size="3">
				<FormControlLabel
					style={{
						marginTop: "0.5rem"
					}}
					control={
						<Switch
							checked={user.config.haveResultAccess}
							onChange={handleResultAccess}
							color="primary"
						/>
					}
					label="Autorisé l'accès au client"
				/>
			</MDBCol>
		</MDBRow>
	)
}

export default Header