import React, {useEffect, useState} from "react";
import firebase from '../api/firebase.api'

const FirebaseStorageImage = ({src, alt, style}) => {
	const [url, setUrl] = useState(null)

	useEffect(() => {
		firebase
			.storage()
			.ref(src)
			.getDownloadURL()
			.then(setUrl)
	})

	return (
		<div className="text-center mt-4 mb-5">
			<img style={style} src={url} alt={alt}/>
		</div>
	)
}

export default FirebaseStorageImage
