import React from 'react'
import {LinearProgress, useTheme} from "@material-ui/core";
import {formatTime} from "../../utils/number.utils";

import TimerIcon from '@material-ui/icons/Timer';

const QuestionnaireHeader = ({actual, length, time, maxTime}) => {
	const theme = useTheme()
	return(
		<div id="questionnaire-header" className="text-center mt-3">
			<div style={{
					display: "inline-flex",
					fontSize: "1.2rem",
					fontWeight: "500",
					color: theme.palette.primary.main
				}}>
				<span>{actual+1}/{length}</span>

				<div className="ml-3">
					<TimerIcon/>
					<span className="ml-1">{formatTime(maxTime-time)}</span>
				</div>
			</div>

			<LinearProgress variant="determinate" value={Math.round((actual / length) * 100)} className="mt-3"/>
		</div>
	)
}

export default QuestionnaireHeader