import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter} from 'react-router-dom';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'

import {MDBSpinner} from "mdbreact";

import ToastContext from "./context/toast.context.js";
import Swal from 'sweetalert2';

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css'

import './index.css';

import App from './App';

const theme = createMuiTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#be1622",
            light: "#f7fafc"
        },
        secondary: {
            main: "#161616",
        }
    },
})
const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toastElement) => {
        toastElement.addEventListener('mouseenter', Swal.stopTimer)
        toastElement.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

ReactDOM.render(
    <Suspense fallback={<MDBSpinner/>}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <ToastContext.Provider value={{toast: toast}}>
                    <App/>
                </ToastContext.Provider>
            </ThemeProvider>
        </BrowserRouter>
    </Suspense>,
    document.getElementById('root')
);
