import React, {useContext} from 'react'

import {MDBIcon, MDBRow} from "mdbreact";
import {Button} from "@material-ui/core";

import firebase from '../../api/firebase.api'
import UserContext from "../../context/user.context";

import logoPatrickRoberto from '../../assets/img/logo-patrick-roberto-ecriture-pc.png'
import NavbarLayout from "./navbar.layout";
import {useHistory} from "react-router-dom";


const HeaderLayout = ({handleOpenAccountDialog}) => {
	const {user} = useContext(UserContext)
	const history = useHistory();

	return(
		<header style={{backgroundColor: "white"}}>
			<div style={{
				backgroundColor: "#f3f3f3",
				fontSize: "18px",
				padding: "0.5rem",
				paddingRight: "3rem",
				paddingLeft: "3rem",
				color: "#000000",
				fontFamily: "Oxygen,sans-serif",
				display: "grid",
				gridTemplateColumns: "auto auto",
				alignItems: "center",
				gridColumnGap: "20px",
				overflowWrap: "anywhere",
			}}>
				<div>
					<MDBIcon icon="map-marker" className="text-dark mr-1"/>
					12 rue de Versailles, 75000 Paris

					<MDBIcon icon="envelope" className="text-dark mr-1 ml-3"/>
					signature@roberto.coach
				</div>

				<div style={{textAlign: "end"}} className="mr-3">
					<MDBIcon icon="phone" className="text-dark mr-1"/>
					+33 6 09 05 45 45
				</div>
			</div>

			<MDBRow center={true} className="text-center mx-0 px-0">
				<img
					onClick={()=>{history.push('/')}}
					alt="logo"
					src={logoPatrickRoberto}
					style={{
						maxWidth: "10vw",
						paddingTop: "1rem",
						paddingBottom: "1rem",
						cursor: "pointer"
					}}
				/>

				<div style={{
					display: "grid",
					right: "2rem",
					position: "absolute",
					paddingBottom: "0.5rem",
					paddingTop: "5rem"
				}}>
					{user !== null ? (
						<React.Fragment>
							<span style={{fontSize: "1.5em", fontWeight: "400"}}>
								{user.firstname} {user.lastname}
							</span>
							<Button color="primary" className="pb-2 mt-2" onClick={handleOpenAccountDialog}>
								<MDBIcon icon="user" fal={true} className="mr-2"/>
								Mon compte
							</Button>
							<Button color="primary" className="pb-2" onClick={()=>{firebase.auth().signOut()}}>
								<MDBIcon icon="sign-out" fal={true} className="mr-2"/>
								Deconnexion
							</Button>
						</React.Fragment>
					) : (
						<Button color="primary" className="pb-2" onClick={()=>{history.push('/connexion')}}>
							<MDBIcon icon="sign-in" fal={true} className="mr-2"/>
							Se connecter
						</Button>
					)}
				</div>
			</MDBRow>

			{user !== null && <NavbarLayout/>}
		</header>
	)
}

export default HeaderLayout